import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
//import { getProofForAddress } from "./merkleproof";
//import CountdownTimer2 from "./countdownfinal";
//import CountdownTimer from "./CountdownTimer";
//import CountdownTimerNotUsed from "./CountdownTimer";
import * as s from "./styles/globalStyles.js";
import styled from "styled-components";
import logo1 from "./config/images/logo.png"
import kanji from './config/images/yurei_kanji.png'
//import './styles/theme.css'
//const { soliditySha3 } = require("web3-utils");
//import logo2 from "./config/images/logo-removebg.png"

export const StyledButton = styled.button`
padding: 10px;
border-radius: 20px;
border: none;
margin-left: 5px;
margin-right: 5px;
background-color: var(--secondary);
font-weight: bold;
color: var(--accent-text);
width: 234px;
height: 50px;
cursor: pointer;
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 20px;
  border: none;
  background-color: var(--secondary);
  font-weight: bold;
  color: var(--accent-text);
  width: 56px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  max-width: 7%;
  transition: width 0.5s;
  transition: height 0.5s;
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const HalfStyle = styled.img`
transition: width 0.5s;
transition: height 0.5s;
max-width: 4%;
margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const kanjiImage = styled.img`
transition: width 0.5s;
transition: height 0.5s;
max-width: 4%;
margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const HeadingMain = styled.h1`
  font-size: 170px;
  font-weight: 400;
  line-height: 223.53px;
  color: #fff;
  text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
  @media (max-width: 767px) {
    font-size: 100px;
    line-height: 111px;
  }
`;
export const FooterImg = styled.img`
  width: 70%;
  height: auto;
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
  }
  @supports (-webkit-touch-callout: none) {
    width: 100%;
    height: auto;
  }
`;
export const FooterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Styledsocial = styled.img`
  width: 36px;
  height: 29px;
  margin-left: 30px;
  @media (min-width: 767px) {
    width: 36px;
    height: 29px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;
export const Styledsocialleft = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
export const StyledsocialRight = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledImg = styled.img`
  max-width: 70%;
  transition: width 0.5s;
  transition: height 0.5s;
  
`;

export const StyledIcon = styled.ul`
  display: flex;
`;

export const IconItem = styled.li`
  flex-direction: row;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
  

//main APP
//var CanMint;
//var balanceOfWallet;
function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("");
  const [claimingNft, setClaimingNft] = useState(false);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 1,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: true,
  });

  const claimNFTs = () => {
    //let userProof = getProofForAddress(blockchain.account);
    //console.warn(userProof);
    //let gasLimit = CONFIG.GAS_LIMIT;
    //let totalGasLimit = String(gasLimit);
    setFeedback("Claiming your Gift...");
    setClaimingNft(true);
    blockchain.smartContract.methods
      .SoulOniMint()
      .send({
        gasLimit: CONFIG.GAS_LIMIT,
        //gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        //value: totalCostWei,
        //value: blockchain.web3.utils.toWei((0.00001 * mintAmount).toString(), "ether"),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          "Mint successful. Visit OpenSea.io to view it."
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  //const tokenAddress = "0x54251bc32A9f389DF7c764AB50BB829ccDcB41bc";
  //let balance = data.paused;
  //console.warn(balance);
//CountDown

  //const THREE_DAYS_IN_MS = 13 * 60 * 60 * 1000;
  //const NOW_IN_MS = new Date().getTime();

  //const dateTimeAfterThreeDays = THREE_DAYS_IN_MS+ NOW_IN_MS;
  //const isSaleActive = Date.now() > dateTimeAfterThreeDays
  //console.log(new Date("18 May 2022 12:00:00").getTime() > new Date().getTime())
  
  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      //let CanClaim = blockchain.smartContract.methods.Claimable(blockchain.account).call();
      //CanClaim.then(function(result){
       // CanMint = result;
        //balanceOfWallet = result;
     // })
    }
  };

//console.log(CanMint)
  const getConfig = async () => {
    const configResponse = await fetch("./config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }, 
    });

    const config = await configResponse.json();
    SET_CONFIG(config);
  };
  
  useEffect(() => {
    getConfig();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container flex={1} image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null} >
      <s.SpacerMedium />
      <s.SpacerMedium />
      <ResponsiveWrapperHeader>
        
          <div>
            <HalfStyle
              alt={"/config/images/logo.png"}
              src={logo1}
              //height='184px'
              //width='95px'
            
            />
          </div>

        </ResponsiveWrapperHeader>

        <s.SpacerMedium />

        <ResponsiveWrapper flex={1} style={{ padding: 5 }} position={"relative"}>

            <StyledLogo
              alt={"/config/images/yurei_kanji.png"}
              src={kanji}
            
            />
            
          <s.Container
            flex={38} 
            jc={"center"}
            ai={"center"}
            style={{ paddingTop: "50px" }}
          >
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: "30px",
                fontWeight: "400",
                color: "var(--accent-text)",
              }}
            >
              <p>Yurei Phase 3: Joumeijin free claim for Soul Oni holders</p>
            </s.TextTitle>
            <HeadingMain>

            </HeadingMain>
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  The sale has ended.
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>

                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >

                </s.TextTitle>

                <s.SpacerSmall />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    
                    <s.SpacerSmall />
                    <StyledButton
                      disabled={new Date("26 February 2023 21:00:00+09:00").getTime() > new Date().getTime()}
                      onClick={e => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                        
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>

                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        
                      </s.TextDescription>

                    
                    </s.Container>

                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 :  0}
                        style={{ marginTop: "10px" }}
                        onClick={e => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "BUSY" :  "Claim" }
                      </StyledButton>
                    </s.Container>
                    <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  <s.SpacerSmall />

                  </s.TextTitle>
                  </>
                )}
              </>
            )}
          </s.Container>

        </ResponsiveWrapper>
        <s.SpacerSmall />
        <s.Container jc={"center"} ai={"center"} style={{ width: "100%" }}>
        <div>
          
            <StyledIcon>
              <IconItem>
                <a href='https://twitter.com/YureiSpirit'>
                  <Styledsocial
                    alt={"social-icon"}
                    src={"/config/images/icon-1.svg"}
                    height='60px'
                    width='116px'
                  />
                </a>
              </IconItem>
              <IconItem>
                <a href='https://discord.gg/fbtEX8Bgcz'>
                  <Styledsocial
                    alt={"social-icon"}
                    src={"/config/images/icon-2.svg"}
                    height='60px'
                    width='116px'
                  />
                </a>
              </IconItem>
            </StyledIcon>
          </div>
          <s.SpacerMedium/>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
